import {Component} from '@angular/core';
import {registerLocaleData} from "@angular/common";
import localeDe from "@angular/common/locales/de-CH";
import localeDeExtra from "@angular/common/locales/extra/de-CH";
import {RouterOutlet} from '@angular/router';

@Component({
  selector: 'mp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {

  constructor() {
    registerLocaleData(localeDe, 'de-CH', localeDeExtra);
  }
}
