import {Injectable, Type} from '@angular/core';
import {ComponentLoaderService} from "@fr2024/editor-new/lib/editor/services/component-loader.service";
import {EditorTreeRoot} from "@sv/swagger/riva-api";

@Injectable({
  providedIn: 'root'
})
export class MpViewerComponentLoaderService extends ComponentLoaderService {

  override getComponent(elementEnum: EditorTreeRoot.ModuleNameEnum): Promise<Type<any> | void> {
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Text) {
      return import('@fr2024/editor-new/lib/viewer/viewers/text-viewer/text-viewer.component')
        .then(value => value.TextViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Root) {
      return Promise.resolve();
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Placeholder) {
      return import('@fr2024/editor-new/lib/viewer/viewers/noop-viewer/noop-viewer.component')
        .then(value => value.NoopViewerComponent);
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpLead) {
      return import('../viewers/mp-lead-viewer/mp-lead-viewer.component')
        .then(value => value.MpLeadViewerComponent);
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpLiteratureReference) {
      return import('../viewers/mp-literature-reference-viewer/mp-literature-reference-viewer.component')
        .then(value => value.MpLiteratureReferenceViewerComponent);
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.ListModule) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-list-viewer/list-viewer/sv-list-viewer.component')
        .then(value => value.SvListViewerComponent);
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpLink) {
      return import('../viewers/mp-link-viewer/mp-link-viewer.component')
        .then(value => value.MpLinkViewerComponent);
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Video) {
      return import('../viewers/mp-video-viewer/sv-video-viewer.component')
        .then(value => value.SvVideoViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Quotes) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-quote-viewer/sv-quote-viewer.component')
        .then(value => value.SvQuoteViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Iframe) {
      return import('../viewers/mp-iframe-viewer/mp-iframe-viewer.component')
        .then(value => value.MpIframeViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpGallery) {
      return import('../viewers/mp-gallery-viewer/mp-gallery-viewer.component')
        .then(value => value.MpGalleryViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Image) {
      return import('../viewers/mp-image-viewer/mp-image-viewer.component')
        .then(value => value.MpImageViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpAccordionDownload) {
      return import('../viewers/sv-accordion-download-viewer/sv-accordion-download-viewer.component')
        .then(value => value.SvAccordionDownloadViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpAccordion) {
      return import('../viewers/mp-accordion-viewer/mp-accordion-viewer.component')
        .then(value => value.MpAccordionViewerComponent);
    }
    if(elementEnum === EditorTreeRoot.ModuleNameEnum.MpStripeProducts) {
      return import('../viewers/mp-stripe-products-viewer/mp-stripe-products-viewer.component')
        .then(val => val.MpStripeProductsViewerComponent)
    }
    if(elementEnum === EditorTreeRoot.ModuleNameEnum.MpStripeProductsActive) {
      return import('../viewers/mp-stripe-products-active-viewer/mp-stripe-products-active-viewer.component')
        .then(val => val.MpStripeProductsActiveViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.ExpandableModule) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-expandable-viewer/sv-expandable-viewer.component')
        .then(value => value.SvExpandableViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpExpandableModule) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-expandable-viewer/sv-expandable-viewer.component')
        .then(value => value.SvExpandableViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Table) {
      return import('@fr2024/editor-new/lib/viewer/viewers/table-viewer/table-viewer.component')
        .then(value => value.TableViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpSubtitleOne) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-subtitle-one-viewer/sv-subtitle-one-viewer.component')
        .then(value => value.SvSubtitleOneViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpSubtitleTwo) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-subtitle-two-viewer/sv-subtitle-two-viewer.component')
        .then(value => value.SvSubtitleTwoViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpMainTitle) {
      return import('@fr2024/editor-new/lib/viewer/viewers/sv-main-title-viewer/sv-main-title-viewer.component')
        .then(value => value.SvMainTitleViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.Audio) {
      return import('../viewers/mp-audio-player-viewer/mp-audio-player-viewer.component')
        .then(value => value.MpAudioPlayerViewerComponent)
    }
    if (elementEnum === EditorTreeRoot.ModuleNameEnum.MpArticle) {
      return import('../viewers/mp-article-viewer/mp-article-viewer.component')
        .then(value => value.MpArticleViewerComponent)
    }
    return Promise.resolve()
  }
}
