import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'svSplit',
  standalone: true,
})
export class SvSplitPipe implements PipeTransform {

  transform(value: string | undefined | null, delimiter: string, start: number | undefined, end?: number): string {
    if (!value || !delimiter) {
      return value || '';
    }

    const segments = value.split(delimiter);

    if (start && !end) {
      if (segments.length) {
        return segments.slice(start)[0];
      }
    }
    if (start && end) {
      if (segments.length) {
        return segments.slice(start, end)[0];
      }
    }

    return '';
  }

}
