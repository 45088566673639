import {Component, Input} from '@angular/core';

import {LinkTypeEnum} from "@sv/sv-common/lib/enum/linkTypeEnum";
import {MpLinkComponent} from "@fr2024/mp-ui/lib/mp-link/mp-link.component";

@Component({
  selector: 'mp-inline-link-viewer',
  standalone: true,
  imports: [
    MpLinkComponent,
  ],
  templateUrl: './mp-inline-link-viewer.component.html',
  styleUrls: ['./mp-inline-link-viewer.component.scss'],
})
export class MpInlineLinkViewerComponent {


  @Input()
  linkType: LinkTypeEnum | undefined;

  @Input()
  link: string | undefined;

  @Input()
  entityId: string | undefined;

  @Input()
  entityType: string | undefined;

  @Input()
  bucket: string | undefined;

  @Input()
  path: string | undefined;

  @Input()
  openInNewTab: boolean = false;

  @Input()
  displayText: string | undefined;

  @Input()
  showFileSize: boolean = false;

  @Input()
  showFileType: boolean = false;
}
