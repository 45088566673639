import "reflect-metadata";
import {importProvidersFrom} from '@angular/core';
import '@angular/localize/init';
import 'hammerjs';


import {AppComponent} from "./app/app.component";
import {EnvModule} from "@sv/sv-common/lib/env/env.module";
import {SvButtonModule} from "@sv/sv-common/lib/components/sv-button/sv-button.module";
import {environment} from "./environments/environment";
import {
  MediaLibraryModalEnvModule
} from "@fr2024/media-library/lib/nebular/media-library-modal-env/media-library-modal-env.module";
import {ToastrModule, ToastrService} from "ngx-toastr";
import {userBackendModuleProvider} from "@sv/sv-common/lib/api-modules/user-backend-module.provider";
import {marketplaceModuleProvider} from "@sv/sv-common/lib/api-modules/marketplace-module.provider";
import {mediaManagerProvider} from "@sv/sv-common/lib/api-modules/media-manager.provider";
import {rivaBackendModuleProvider} from "@sv/sv-common/lib/api-modules/riva-backend-module.provider";
import {appRoutes} from "./app/app.routes";
import {provideRouter, RouteReuseStrategy, withEnabledBlockingInitialNavigation} from "@angular/router";
import {MpInlineLinkViewerModule} from "./app/editors/viewers/inline-link-viewer/mp-inline-link-viewer.module";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {bootstrapApplication, HammerModule} from "@angular/platform-browser";
import {provideAnimations} from "@angular/platform-browser/animations";
import {ComponentLoaderService} from "@fr2024/editor-new/lib/editor/services/component-loader.service";
import player from "lottie-web";
import {licenseBackendModuleProvider} from "@sv/sv-common/lib/api-modules/license-backend-module.provider";
import {provideLottieOptions} from "ngx-lottie";
import {MpViewerComponentLoaderService} from "./app/editors/component-loaders/mp-viewer-component-loader.service";
import {provideSvAuthorizedService} from "@sv/sv-common/lib/providers/provide-sv-authorized.service";
import {AuthorizedUserService} from "@sv/sv-common/lib/services/auth/authorized-user.service";
import {provideSvToastr} from "@sv/sv-common/lib/providers/provide-sv.toastr";
import {provideFormErrorConfiguration} from "@sv/sv-common/lib/form-helper/form-error/form-error.provider";
import {provideStripeWorkerProvider} from "@sv/sv-common/lib/api-modules/provide-stripe-worker.provider";
import {
  SvRouteReuseStrategyService
} from "@sv/sv-common/lib/reuse-strategy/sv-route-reuse-strategy/sv-route-reuse-strategy.service";
import {NgxFloatUiModule} from "ngx-float-ui";

bootstrapApplication(AppComponent, {
  providers: [
    provideLottieOptions({
      player: () => {
        return player;
      }
    }),
    AuthorizedUserService,
    provideSvToastr(ToastrService),
    provideFormErrorConfiguration({
      svFormErrorClasses: ['copy-5-sans', 'mt-1']
    }),
    provideSvAuthorizedService(AuthorizedUserService),
    {
      provide: RouteReuseStrategy,
      useClass: SvRouteReuseStrategyService
    },
    importProvidersFrom(
      HammerModule,
      MpInlineLinkViewerModule,
      NgxFloatUiModule,
      // ServiceWorkerModule.register('ngsw-worker.js', {
      //   enabled: false,
      //   // Register the ServiceWorker as soon as the app is stable
      //   // or after 30 seconds (whichever comes first).
      //   registrationStrategy: 'registerWhenStable:30000',
      // }),
      rivaBackendModuleProvider,
      mediaManagerProvider,
      licenseBackendModuleProvider,
      marketplaceModuleProvider,
      userBackendModuleProvider,
      licenseBackendModuleProvider,
      provideStripeWorkerProvider,
      ToastrModule.forRoot(),
      MediaLibraryModalEnvModule.forRoot({
        bucket: environment.mock?.BUCKET!
      }), SvButtonModule.forRoot({border: 'square'}), EnvModule.forMarketplace(environment)),
    {
      provide: ComponentLoaderService,
      useClass: MpViewerComponentLoaderService
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
  ]
})
  .catch((err) => console.error(err));
