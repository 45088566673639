// button.module.ts
import {ModuleWithProviders, NgModule} from '@angular/core';
import {SvButtonConfigInterface} from "../../interfaces/sv-button-config-interface";
import {SV_BUTTON_CONFIG_TOKEN} from "./tokens/button-config.token";


@NgModule({})
export class SvButtonModule {
  static forRoot(config: SvButtonConfigInterface): ModuleWithProviders<SvButtonModule> {
    return {
      ngModule: SvButtonModule,
      providers: [
        {provide: SV_BUTTON_CONFIG_TOKEN, useValue: config}
      ]
    };
  }
}
