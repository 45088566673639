@if (displayText && linkType) {
  <mp-link
    [displayText]="displayText"
    [linkType]="linkType"
    [link]="link!"
    [openInNewTab]="openInNewTab"
    [entityType]="entityType"
    [entityId]="entityId"
    [isViewer]="true">
  </mp-link>
}
