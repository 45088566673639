@if (isViewer) {
  @if (linkType === LinkTypeEnum.EXTERNAL) {
    <a href="{{link}}" [target]="targetString">{{ displayText.trim() }}</a>
  }
  @if (linkType === LinkTypeEnum.INTERNAL) {
    <a>{{ displayText.trim() }}</a>
  }
  @if (linkType === LinkTypeEnum.ASSET) {
    <a class="cursor-pointer"
       svDownloadAsset
       [bucket]="link | toFileBucket"
       [path]="link | toFilePartialPath">{{ displayText.trim() }}</a>
  }
} @else {
  @if (linkType === LinkTypeEnum.EXTERNAL) {
    <a nbTooltip="Would lead to {{link}}">
      {{ displayText }}
      <small>({{ openInNewTab ? 'New Tab' : 'Current Tab' }})</small>
    </a>
  }
  @if (linkType === LinkTypeEnum.INTERNAL) {
    <a
      nbTooltip="Internal link. Entity Type:{{entityType}} | ID: {{entityId}}">
      {{ displayText }}
      <small>({{ openInNewTab ? 'New Tab' : 'Current Tab' }})</small>
    </a>
  }
  @if (linkType === LinkTypeEnum.ASSET) {
    <a
      nbTooltip="Would lead to asset {{link | svSplit: '/':  -1}}">
      {{ displayText }}
      <small>({{ openInNewTab ? 'New Tab' : 'Current Tab' }})</small>
    </a>
  }
}
