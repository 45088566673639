import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {createCustomElement} from "@angular/elements";
import {MpInlineLinkViewerComponent} from "./mp-inline-link-viewer.component";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MpInlineLinkViewerComponent
  ]
})
export class MpInlineLinkViewerModule {
  constructor(
    private injector: Injector
  ) {
    if (!customElements.get("web-mp-inline-link-module")) {
      customElements.define("web-mp-inline-link-module", createCustomElement(MpInlineLinkViewerComponent, {
        injector: this.injector
      }))
    }
  }
}
