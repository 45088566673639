import {AfterViewInit, Component, Input, OnInit,} from '@angular/core';

import {DownloadAssetDirective} from "@sv/sv-common/lib/directives/download-asset/download-asset.directive";
import {ToFileBucketPipe} from "@fr2024/media-library/lib/nebular/media-library/file-pipes/to-file-bucket.pipe";
import {
  ToFilePartialPathPipe
} from "@fr2024/media-library/lib/nebular/media-library/file-pipes/to-file-partial-path.pipe";
import {LinkTypeEnum} from '@sv/sv-common/lib/enum/linkTypeEnum';
import { NbTooltipModule } from '@nebular/theme';
import { SvSplitPipe } from '@sv/sv-common/lib/pipes/svSplit.pipe';

@Component({
  selector: 'mp-link',
  standalone: true,
  imports: [
    DownloadAssetDirective,
    ToFileBucketPipe,
    ToFilePartialPathPipe,
    NbTooltipModule,
    SvSplitPipe
  ],
  templateUrl: './mp-link.component.html',
  styleUrls: ['./mp-link.component.scss'],
})
export class MpLinkComponent implements OnInit, AfterViewInit {
  @Input({required: true}) linkType!: LinkTypeEnum;
  @Input({required: true}) link!: string;
  @Input() openInNewTab = false;
  @Input() entityType: string | undefined;
  @Input() entityId: string | undefined;
  @Input({required: true}) displayText!: string;
  @Input() isViewer = false;
  targetString!: string;
  protected readonly LinkTypeEnum = LinkTypeEnum;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setTargetString();
  }

  setTargetString() {
    const isValidValue = ['true', 'false'].includes(String(this.openInNewTab));

    if (!isValidValue) {
      this.targetString = '_self';
      return;
    }

    // @ts-ignore
    this.targetString = this.openInNewTab === 'true' ? '_blank' : '_self';
  }
}
