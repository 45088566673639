import {MpRoutes} from "./extensions/mp-routes";


export const appRoutes: MpRoutes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing.module').then(mod => mod.AuthRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./elements/authorized/authorized-routing.module').then(val => val.AuthorizedRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/errors/error-pages.routes').then(val => val.errorPageRoutes),
  }
];
